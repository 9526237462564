/**
 * Invite page
 * 直接使用注册页面
 */

import RegisterPage from '@/components/layouts/register';
import { Lang } from '@/core/i18n';

const InvitePage = () => <RegisterPage />;

export default Lang.SeoHead(InvitePage);
export const getStaticPaths = Lang.getStaticPaths;
export const getStaticProps = Lang.getStaticProps({ key: 'invite' });
